import moment from 'moment';
import ExcelJS from 'exceljs';
import download from '../../../../images/menu/download.png';
import '../report.css';
import { saveAs } from 'file-saver';

const ExcelSearchStatusReport = ({ data }) => {
  if (Array.isArray(data)) {
    data.forEach((d) => {
      d['Fecha de Ingreso'] = moment(d['Fecha de Ingreso']).format('YYYY-MM-DD');
    });
  }

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Estado de busqueda');

    const columns = [
      { header: 'Nombre y Apellido', key: 'Nombre y Apellido' },
      { header: 'DNI', key: 'DNI' },
      { header: 'Centro de Costos', key: 'Centro de Costos' },
      { header: 'Búsqueda', key: 'Busqueda' },
      { header: 'Estado', key: 'Estado' },
      { header: 'HM', key: 'HM' },
      { header: 'Director', key: 'Director' },
      { header: 'Fecha de Ingreso', key: 'Fecha de Ingreso' },
    ];

    worksheet.columns = columns;
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Estado de busqueda.xlsx');
  };

  return (
    <button className='boton-descargar-excel' onClick={generateExcel}>
      <img src={download} width='20px' height='20px' alt='download' />
    </button>
  );
};

export default ExcelSearchStatusReport;