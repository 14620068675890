import React, { useMemo, useState, useEffect } from "react";
// import { Link } from 'react-router-dom'
import ver from "../../../../../images/menu/ver.png";
import ver_visto from "../../../../../images/menu/ver-visto.png";
import InfoTimeLine from "../../InfoTimeLine/InfoTimeLine";
// import '../../timeLineRRHH.css'
import '../showApplicants.css'
import { API } from "../../../../../http-common";
import styled from "styled-components";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Modal } from "react-bootstrap";
import LoadingList from "../../../Loading/LoadingList";
import { Link } from "react-router-dom";
import { CustomFilter } from "../../../../../helpers/customFilters"
const FILTER_NAME = {
  departament: "Departamento",
  status_name: "Filtrar por",
  where_find: "Origen"
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setTableRelativePage
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setTableRelativePage('first');
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <input
      className="form-control search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    margin-top: 60px;
    overflow-y: hidden;

    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tr {
      td {
        :last-child {
          vertical-align: middle;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data, searchId, departaments }) {
  const currentStateId = 'ListApplicants_'.concat(searchId);

  let initialState = {};
  if (localStorage.getItem('TimelineTableStateId') === currentStateId) {
    try {
      const lastState = JSON.parse(localStorage.getItem('TimelineTableStateData'));
      initialState = lastState || {};
    } catch {
      initialState = {};
    }
  }

  const storeTableStateReducer = (newState, action, prevState) => {
    localStorage.setItem('TimelineTableStateId', currentStateId);
    localStorage.setItem('TimelineTableStateData', JSON.stringify(newState));
    return newState;
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      globalFilter: CustomFilter,
      columns,
      data,
      initialState,
      stateReducer: storeTableStateReducer
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const setTableRelativePage = (page) => {
    switch (page) {
      case 'prev':
        previousPage();
        break;
      case 'next':
        nextPage();
        break;
      case 'first':
        gotoPage(0);
        break;
      case 'last':
        gotoPage(pageCount - 1);
        break;
    }
  }

  const setTablePageNumber = pageNumber => {
    const pageIndex = pageNumber - 1;
    if (pageIndex >= 0 && pageIndex < pageCount) gotoPage(pageIndex);
  }

  // Render the UI for your table
  return (
    <Styles>
      <div className="tableWrap">
        <GlobalFilter
          setTableRelativePage={setTableRelativePage}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        {/* <div className="">
          <span className="filtrar-span">Departamento</span>
          <select
            name="filter"
          >
            <option value="">Todos</option>
            {departaments.map((option, i) => (
              <option key={i} value={option.id_departament}>
                {option.departament}
              </option>
            ))}
          </select>
        </div> */}

        <table className="table" id="candidatos" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    id={column.id}
                    data-sortby={column.isSortedDesc}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}{" "}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div>page {page} {pageOptions.length}</div> */}
        <ul className="pagination">
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("first");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"Primera"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("prev");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"< Anterior"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("next");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Siguiente >"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("last");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Ultimo"}</a>
          </li>
          <li>
            <a className="page-link no-hover">
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{" "}
            </a>
          </li>
          <li className="page-item">
            <span>
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) : 1;
                  setTablePageNumber(pageNumber);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </span>
          </li>{" "}
          <select
            className="page-link no-hover"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ fontSize: "15px", width: "120px", padding: "7px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </ul>
      </div>
    </Styles>
  );
}

// This is a custom filter UI for selecting a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const SelectColumnViewFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <>
      <div className="content-filtrar visto">
        <span className="filtrar-span">Visto</span>
        <select
          name="filter"
          // className='form-control'
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option === 1 ? "Visto" : option === 0 && "No visto"}
              {/* {option} */}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

function SelectStatusFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <>
      <div className={"content-filtrar "+id}>
        <span className="filtrar-span">{FILTER_NAME[id]}</span>
        <select
          name="filter"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const ListApplicants = ({ applicants, idSearch, searchName, departaments, generic }) => {
  const [available, setAvailable] = useState(false);
  const [infoApplicant, setInfoApplicant] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusDetail, setStatusDetail] = useState(null)
  const [err, setErr] = useState(false);
  const [showModal, setShowModal] = useState(false);



  async function getApplicant(application_id, setLoading, setStatusDetail) {
    await API.get("/applications/timeline/" + application_id)
    .then((response) => {
      if(response.status !== 200 || response.data.error === 1){
        setErr(true);
        setShowModal(true)
        setInfo(null)
        setLoading(false);
      }
      else {
        setStatusDetail(response.data.data.application.status_detail_id)
        setInfo(response.data.data.user);
        setLoading(false);
      }
    })
    .catch((err) => {
      if(err.response.status === 404 || err.response.status === 500){
        setErr(true);
        setShowModal(true)
        setInfo(null)
        setLoading(false);
      }
      else {
        setErr(true);
        setShowModal(true)
        setInfo(null)
        setLoading(false);
      }
    });
  }


  const setInfo = (item) => {
   if(item){
    setAvailable(!available);
    setInfoApplicant(item);
   }
    else {
      setAvailable(true);
      setInfoApplicant(null);
    }
  };
  useEffect(() => {
  }, [infoApplicant]);

  const changeRead = (application_id) => {
    API.put("/applications/timeline/" + application_id + "/read");
  };


  const columns = useMemo(
    () => {
      if(generic) {
        return [
          {
            Header: "ID.",
            accessor: "candidate_id",
            disableFilters: true,
            Cell: ({ row }) => <Link to={'/candidatos/'+row.original.candidate_id}>{row.original.candidate_id}</Link>
          },
          {
            Header: "Nombre y apellido",
            accessor: "username",
            disableFilters: true,
            Cell: ({ row }) => <Link to={'/candidatos/'+row.original.candidate_id}>{row.original.username}</Link>
          },
          {
            Header: "Experiencia",
            accessor: "work_experience",
            disableFilters: true,
          },
          {
            Header: "Ultima posición",
            accessor: "last_position",
            disableFilters: true,
            // Cell:row => (<div className="contet-director">Mario Suarez PEREZ GONZALES TORRES</div>)
          },
          {
            Header: "Postulacion N°",
            accessor: "postulations_cant",
            disableFilters: true,
            // Cell: ({row}) => (<div className='color-estado' data-estado={row.original.estado_dir}>?</div>)
          },
          {
            Header: "Recomendado por",
            accessor: "friend_recomended",
            disableFilters: true,
          },
          {
            Header: "Departamento",
            accessor: "departament",
            Filter: SelectStatusFilter
          },
          {
            Header: "Origen",
            accessor: "where_find",
            Filter: SelectStatusFilter
          },
          {
            Header: "Estado",
            accessor: "status_name",
            Filter: SelectStatusFilter,
            filter: "equals",
            Cell: ({ row }) => (
              <div className="estado_general">
                <span className={row.original.status_name}>
                  {row.original.status_name}
                </span>
              </div>
            ),
          },{
            Header: "PERFIL",
            accessor: "profile_detail",
            disableFilters: true,
            Cell: ({ row }) => (
          
              <div className="estado_general">
                {
                  (row.original.status_detail_id === 15) ?
                  <span className={row.original.profile_detail}>
                  {row.original.profile_detail}
                </span> : ''
                }
                
              </div>
            ),
          }
          ,
          {
            Header: "",
            accessor: "read",
            disableSortBy: true,
            Filter: SelectColumnViewFilter,
            Cell: ({ row }) => (
              <div style={{ cursor: `pointer` }}>
                <img
                  style={{ display: `block`, margin: `0 auto`, width: "22px" }}
                  className={`ver-candidato ${row.original.candidate_id}`}
                  src={row.original.read === 0 ? ver : ver_visto}
                  alt=""
                  onClick={() => {
                    setLoading(true);
                    getApplicant(row.original.application_id, setLoading, setStatusDetail);
                    changeRead(row.original.application_id);
                  }}
                />
              </div>
            ),
          },
        ]
      }else{
        return [
          {
            Header: "ID.",
            accessor: "candidate_id",
            disableFilters: true,
            Cell: ({ row }) => <Link to={'/candidatos/'+row.original.candidate_id}>{row.original.candidate_id}</Link>
          },
          {
            Header: "Nombre y apellido",
            accessor: "username",
            disableFilters: true,
            Cell: ({ row }) => <Link to={'/candidatos/'+row.original.candidate_id}>{row.original.username}</Link>
          },
          {
            Header: "Experiencia",
            accessor: "work_experience",
            disableFilters: true,
          },
          {
            Header: "Ultima posición",
            accessor: "last_position",
            disableFilters: true,
            // Cell:row => (<div className="contet-director">Mario Suarez PEREZ GONZALES TORRES</div>)
          },
          {
            Header: "Postulacion N°",
            accessor: "postulations_cant",
            disableFilters: true,
            // Cell: ({row}) => (<div className='color-estado' data-estado={row.original.estado_dir}>?</div>)
          },
          {
            Header: "Recomendado por",
            accessor: "friend_recomended",
            disableFilters: true,
          },
          {
            Header: "Origen",
            accessor: "where_find",
            Filter: SelectStatusFilter
          },
          {
            Header: "Estado",
            accessor: "status_name",
            Filter: SelectStatusFilter,
            filter: "includes",
            Cell: ({ row }) => (
              
              <div className="estado_general">
                <span className={row.original.status_name}>
                  {row.original.status_name}
                </span>
              </div>
            ),
          },
          {
            Header: "PERFIL",
            accessor: "profile_detail",
            disableFilters: true,
            Cell: ({ row }) => (
          
              <div className="estado_general">
                {
                  (row.original.status_detail_id === 15) ?
                  <span className={row.original.profile_detail}>
                  {row.original.profile_detail}
                </span> : ''
                }
                
              </div>
            ),
          },
          {
            Header: "",
            accessor: "read",
            disableSortBy: true,
            Filter: SelectColumnViewFilter,
            Cell: ({ row }) => (
              <div  className="view-eyes-in-show">
                 {
                    row.original.applications_count > 1 ? <span className="applicant-count"> <strong>+1</strong></span> : <p></p>
                 }
                <img
                  style={{ display: `block`, margin: `0 5px`, width: "22px" }}
                  className={`ver-candidato ${row.original.candidate_id}`}
                  src={row.original.read === 0 ? ver : ver_visto}
                  alt=""
                  onClick={() => {
                    setLoading(true);
                    getApplicant(row.original.application_id, setLoading, setStatusDetail);
                    changeRead(row.original.application_id);
                  }}
                />
              </div>
            ),
          },
        ]
      }
    },
    []
  );

  if (!available) {
    return (
      <>
        <div className="content-busqueda">
          <div className="contenido">
            <div className="content-table">
              <div className="candidatos">
                <div className="content-candidatos">
                  {/* <div class="content-candidatos" id="content-candidatos"> */}
                  <Table columns={columns} data={applicants} departaments={departaments} searchId={idSearch} />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={loading}
          backdrop={'static'}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >

          <Modal.Body>
            <LoadingList padding="30px 0" size={50} />
          </Modal.Body>
        </Modal>
      </>
    );
  } else if( err === true) {
    return (
      <Modal
      size="md"
      show={showModal}
      aria-labelledby="example-modal-sizes-title-lg"
      className='modal_edit_applicant'
      centered
    >
      <Modal.Header>
      </Modal.Header>
      <Modal.Body>
        <p>Ocurrió un error al obtener los datos, por favor intente nuevamente.</p>
        <Link to={'/busqueda'}>Cerrar</Link>
      </Modal.Body>
    </Modal>
     )
  }
  else {
    return (
      loading ? <LoadingList padding="30px 0" size={50} /> :
      <InfoTimeLine
      idSearch={idSearch}
      available={available}
      setAvailable={setAvailable}
      applicant={infoApplicant}
      searchName={searchName}
      statusDetail={statusDetail}
    />
    );
  }
  
};

/*const ListApplicants = (applicants) => {
  const [available, setAvailable] = useState(false)
  const [infoApplicant, setInfoApplicant] = useState([])

  function getApplicant(id) {
    API.get('/applications/application_id/'+id)
        .then(function (response) {
          if (response.data.error === 0) {
            setInfo(response.data.data.user)
          } else {
              //todo
          }
        })
  }

  const setInfo = (item) => {
    setAvailable(!available)
    setInfoApplicant([])
    setInfoApplicant(item)
  }

    if (!available) {
      return (
        <div className="content-candidatos" id="content-candidatos">
          <table id="candidatos">
            <div className="content-filtrar">
              <span className="filtrar-span">Filtrar por</span>
              <select name="filter">
                <option value="">Selecionar</option>
                <option data-6="avanza">Avanza</option>
                <option data-6="pendiente entrevista">Pendiente entrevista</option>
                <option data-6="candidato guardado">Candidato guardado</option>
                <option data-6="pendiente">Pendiente</option>
                <option data-6="no avanza">No avanza</option>
              </select>
            </div>
            <thead>
              <tr>
                <th>BÚSQUEDA</th>
                <th>NOMBRE Y APELLIDO</th>
                <th>EDUCACIÓN</th>
                <th>EXPERIENCIA</th>
                <th>ÚLTIMA POSICIÓN</th>
                <th>POSTULACIÓN NR°</th>
                <th>ESTADO</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {applicants.applicants.map((item, i) =>
                <tr className="pendiente" key={i}> {/!* finalizado *!/}
                  <td>{item.position_id}</td>
                  <td><b>{item.username}</b></td>
                  <td>{item.education_level}</td>
                  <td>{item.work_experience}</td>
                  <td>{item.last_position}</td>
                  <td>{item.postulations_cant}</td>
                  <td><span className="avanza">{item.status_name}</span></td>{/!* pendiente-entrevista, candidato-guardado, pendiente, rechazada *!/}
                  <td>
                    <img className={`ver-candidato ${item.candidate_id}`} src={ver} alt="" onClick={() => getApplicant(item.candidate_id)} />
                  </td>
                </tr>
                )
              }
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <InfoTimeLine available={available} setAvailable={setAvailable} applicant={infoApplicant}/>
      )
    }
}*/

export default ListApplicants;
