import ExcelJS from 'exceljs';
import download from '../../../../images/menu/download.png';
import '../report.css';
import moment from 'moment';
import { saveAs } from 'file-saver';

const ExcelAdmissionsReport = ({ data }) => {
  if (Array.isArray(data)) {
    data.forEach((d) => {
      d['Fecha de Nacimiento'] = moment(d['Fecha de Nacimiento']).format('YYYY-MM-DD');
    });
  }

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Ingresos');

    const columns = [
      { header: 'Nombre', key: 'Nombre' },
      { header: 'Status', key: 'Status' },
      { header: 'DNI', key: 'DNI' },
      { header: 'Teléfono', key: 'Teléfono' },
      { header: 'Título', key: 'Título' },
      { header: 'Universidad', key: 'Universidad' },
      { header: 'E-mail', key: 'E-mail' },
      { header: 'Último puesto', key: 'Último Puesto' },
      { header: 'Fecha de Nacimiento', key: 'Fecha de Nacimiento' },
      { header: 'Educacion', key: 'Educación' },
      { header: 'Candidato Resaltado', key: 'Candidato Resaltado' },
      { header: 'Presentado por', key: 'Presentado por' },
      { header: 'Busqueda', key: 'Busqueda' },
      { header: 'Centro de Costos', key: 'Centro de Costos' },
    ];

    worksheet.columns = columns;
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Ingresos.xlsx');
  };

  return (
    <button className='boton-descargar-excel' onClick={generateExcel}>
      <img src={download} width='20px' height='20px' alt='download' />
    </button>
  );
};

export default ExcelAdmissionsReport;