import ExcelJS from 'exceljs';
import download from '../../../../images/menu/download.png';
import '../report.css';
import optionsList from '../../NewHunt/OptionsList.json';
import { saveAs } from 'file-saver';

const ExcelHunteoReport = ({ data }) => {
  data = data.map((d) => {
    let country = optionsList.countries.find((option) => option.code === d.country);
    if (typeof country !== 'undefined') {
      d.country = country.name;
    }
    return d;
  });

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Registro de hunteo');

    const columns = [
      { header: 'Nombre', key: 'Nombre' },
      { header: 'Perfil de linkedin', key: 'Perfil de linkedin' },
      { header: 'Perfil de contrato', key: 'Perfil de contrato' },
      { header: 'Empresa', key: 'Empresa' },
      { header: 'Cargo/Rol', key: 'Cargo/Rol' },
      { header: 'Estado', key: 'Estado' },
      { header: 'Primera fecha de contacto', key: 'Primera fecha de contacto' },
      { header: 'Última fecha de contacto', key: 'Última fecha de contacto' },
      { header: 'País', key: 'country' },
      { header: 'Correo electónico', key: 'Correo electónico' },
      { header: 'Teléfono', key: 'Teléfono' },
      { header: 'Notas', key: 'Notas' },
    ];

    worksheet.columns = columns;
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Registro de hunteo.xlsx');
  };

  return (
    <button className='boton-descargar-excel' onClick={generateExcel}>
      <img src={download} width='20px' height='20px' alt='download' />
    </button>
  );
};

export default ExcelHunteoReport;