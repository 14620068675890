import ExcelJS from 'exceljs';
import download from '../../../../images/menu/download.png';
import '../report.css';
import { saveAs } from 'file-saver';

const ExcelSearchesReport = ({ data }) => {
  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Busquedas');

    const columns = [
      { header: 'ID de Busqueda', key: 'ID de Busqueda' },
      { header: 'Nombre de la busqueda', key: 'Nombre de la busqueda' },
      { header: 'Centro de Costos', key: 'Centro de Costos' },
      { header: 'Descripción de la Tarea', key: 'Descripción de la Tarea' },
      { header: 'Descripción del Perfil', key: 'Descripción del Perfil' },
      { header: 'Motivo de la Busqueda', key: 'Motivo de la Busqueda' },
      { header: 'Nombre del Creador', key: 'Nombre del Creador' },
      { header: 'Nombre del Director', key: 'Nombre del Director' },
      { header: 'Nombre del HM', key: 'Nombre del HM' },
      { header: 'Sector', key: 'Sector' },
      { header: 'Seniority', key: 'Seniority' },
      { header: 'Status Director', key: 'Status Director' },
      { header: 'Status RRHH', key: 'Status RRHH' },
      { header: 'Status de la Busqueda', key: 'Status de la Busqueda' },
    ];

    worksheet.columns = columns;
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Busquedas.xlsx');
  };

  return (
    <button className='boton-descargar-excel' onClick={generateExcel}>
      <img src={download} width='20px' height='20px' alt='download' />
    </button>
  );
};

export default ExcelSearchesReport;