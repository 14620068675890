import { normalizeText } from "normalize-text";

export const convertDate = (dateString) => {
  if (!dateString) return new Date(0); // Return a very old date for empty strings
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

export const CustomFilter = (rows, columnIds, globalFilterValue) => {
    const normalizedGlobalFilterValue = normalizeText(globalFilterValue).toLowerCase();
    const searchWords = normalizedGlobalFilterValue.split(" ");
    
    return rows.filter((row) => {
        for (const columnId of columnIds) {
            const cellValue = normalizeText(String(row.values[columnId])).toLowerCase();
            
            if (columnId === "username") {
                if (searchWords.every(word => cellValue.includes(word))) return true;
            } else {
                if (cellValue.includes(normalizedGlobalFilterValue)) return true;
            }
        }
        return false;
    });
}

