import React, { useEffect, useState, useContext, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./hunt.css";
import ver from "../../../images/menu/ver.png";
import deleteIcon from "../../../images/menu/delete.png";
import ver_visto from "../../../images/menu/ver-visto.png";
import { API } from "../../../http-common";
import { UserContext } from "../../../context/userContext";
import { Row,Col, Modal, ModalBody, Spinner } from "react-bootstrap";
import Accordion1 from "../TimeLineRRHH/ShowApplicants/ApplicantEvaluation/Accordion1/Accordion1";
import Accordion2 from "../TimeLineRRHH/ShowApplicants/ApplicantEvaluation/Accordion2/Accordion2";
import Accordion3 from "../TimeLineRRHH/ShowApplicants/ApplicantEvaluation/Accordion3/Accordion3";
import Accordion5 from "../TimeLineRRHH/ShowApplicants/ApplicantEvaluation/Accordion5/Accordion5";
import LoadingList from "../Loading/LoadingList";
import  "../../ModalGenericoBalanz/ModalBalanz.css"
import { CustomFilter } from '../../../helpers/customFilters'
const FILTER_NAME = {
  first_contact: "Primera fecha contacto",
  status_name: "Filtrar por",
  last_contact: "Última fecha contacto"
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setTableRelativePage
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setTableRelativePage('first');
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <input
      className="form-control search candidatos-search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    margin-top: 30px;
    max-width: 100%;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .page-link {
    cursor: pointer;
  }
`;

function Table({ columns, data, loading }) {
  const currentStateId = 'ListApplicants_0';

  let initialState = {};
  if (localStorage.getItem('ApplicantsTableStateId') === currentStateId) {
    try {
      const lastState = JSON.parse(localStorage.getItem('ApplicantsTableStateData'));
      initialState = lastState || {};
    } catch {
      initialState = {};
    }
  }

  const storeTableStateReducer = (newState, action, prevState) => {
    localStorage.setItem('ApplicantsTableStateId', currentStateId);
    localStorage.setItem('ApplicantsTableStateData', JSON.stringify(newState));
    return newState;
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    { 
      globalFilter: CustomFilter,
      columns,
      data,
      initialState,
      stateReducer: storeTableStateReducer
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const setTableRelativePage = (page) => {
    switch (page) {
      case 'prev':
        previousPage();
        break;
      case 'next':
        nextPage();
        break;
      case 'first':
        gotoPage(0);
        break;
      case 'last':
        gotoPage(pageCount - 1);
        break;
    }
  }

  const setTablePageNumber = pageNumber => {
    const pageIndex = pageNumber - 1;
    if (pageIndex >= 0 && pageIndex < pageCount) gotoPage(pageIndex);
  }

  // Render the UI for your table
  return (
    <Styles>
      <div className="tableWrap">
        {
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setTableRelativePage={setTableRelativePage}
          />
        }
        <table className="table table_candidatos" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    id={column.id}
                    data-sortby={column.isSortedDesc}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}{" "}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span id="descending"> 🔽</span>
                        ) : (
                          <span id="ascending"> 🔼</span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <LoadingList />
                </td>
              </tr>
            )}
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.status} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <ul className="pagination">
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("first");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"Primera"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("prev");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"< Anterior"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("next");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Siguiente >"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("last");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Ultimo"}</a>
          </li>
          <li className="page-item">
            <span className="page-link no-hover">
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{" "}
            </span>
          </li>
          <li className="page-item">
            <span>
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) : 1;
                  setTablePageNumber(pageNumber);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </span>
          </li>
          <li className="page-item ">
            <select
              className="page-link no-hover"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              style={{ fontSize: "15px", width: "120px", padding: "7px" }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </Styles>
  );
}
// highlighted
// This is a custom filter UI for selecting a unique option from a list
const SelectColumnProfileFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  
  // Render a multi-select box
  return (
    <>
      <div className="content-filtrar profile">
        <span className="filtrar-span">Perfil contacto</span>
        <select
          name="filter"
          // className='form-control'
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>{option}</option>
          ))}
        </select>
      </div>
    </>
  );
};


// This is a custom filter UI for selecting a unique option from a list
// function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id },}) {
//     // Calculate the options for filtering using the preFilteredRows
//     const options = React.useMemo(() => {
//         const options = new Set()
//         preFilteredRows.forEach(row => {
//             options.add(row.values[id])
//         })
//         return [...options.values()]
//     }, [id, preFilteredRows])

//     // Render a multi-select box
//     return (
//         <select
//             value={filterValue}
//             onChange={e => {
//                 setFilter(e.target.value || undefined)
//             }}
//         >
//             <option value="">Todos</option>
//             {options.map((option, i) => (
//                 <option key={i} value={option}>
//                     {option}
//                 </option>
//             ))}
//         </select>
//     )
// }
function SelectStatusFilter({
 column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // caso defechas
  const contactNames = ['first_contact', 'last_contact'];
  if(contactNames.includes(id)) {
    return (
      <>
     <div className={"content-filtrar "+id}>
          <span className="filtrar-span">{FILTER_NAME[id]}</span>

          <input 
            type="date" 
            name="filter"
            value={filterValue} 
            onChange={(e) => {
              setFilter(e.target.value || undefined)
            }}
          />
          
        </div>
      </>
    );
  }
  return (
    <>
   <div className={"content-filtrar "+id}>
        <span className="filtrar-span">{FILTER_NAME[id]}</span>
  
        <select
          name="filter"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined)
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}


const Hunt = () => {
  const { userData, setUserData } = useContext(UserContext);

  const [hunt, setHunt] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalByDelete, setShowModalByDelete] = useState(false);

  const [infoApplicant, setInfoApplicant] = useState([]);
  const [infoMudules, setInfoModules] = useState([]);

  const [idSearch, setIdSearch] = useState(null);
  const [loading, setLoading] = useState(true)
  const [huntId, setHuntId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [HuntError, setHuntError] = useState(false)
  const handleClose = () => setShowModal(false);

  const handleShow = (info) => {
    setIdSearch(info.id_search);
    getApplicant(info.application_id);
    // setShowModal(true)
  };

  

  function getApplicant(application_id) {
    API.get("/applications/timeline/" + application_id).then(function (
      response
    ) {
      if (response.data.error === 0) {
        setInfo(response.data.data);
        setShowModal(true);
        
      } else {
        //todo
      }
    });
  }
  const handleShowModal = (id) => {
    
    setHuntId(id);
    setShowModalByDelete(true);

  }
  const handleCloseModal = () => setShowModalByDelete(false);

  const removeHunt = async (id) => {
    setIsLoading(true);
    try {
      const response = await API.post("/hunt/delete/"+id);
      if(response.data.error === 400 || response.data.error === 401 || response.data.error === 500) {
        setIsLoading(false);
        setHuntError(true)
      }
      else {
        setHuntError(false)
        setShowModalByDelete(false);
        setIsLoading(false);
      }
      getHunt();
    } catch (error) {
      setIsLoading(false);
      setHuntError(true)
      console.log(error);
    }
    }
  
  const changeRead = (application_id) => {
    API.put("/applications/timeline/" + application_id + "/read");
    // .then(function (response) {})
  };

  const setInfo = (item) => {
    setInfoApplicant(item.user);
    setInfoModules(item.modules[4]);
    
  };

  // function getHunt() {
  //   // @todocambiar la url de la API para q traiga solo busquedas para aprobar
  //   API.get("/candidates").then(function (response) {
  //     if (response.data.error === 0) {
  //       let lastCandidateId = null;
  //       let fullCandidates = response.data.data.filter((candidate) => {
  //         let duplicate = lastCandidateId === candidate.id;
  //         lastCandidateId = candidate.id;
  //         return !duplicate;
  //       });
  //       //setHunt(response.data.data)
  //       setHunt(fullCandidates);
  //     } else {
  //       //todoSelectColumnViewFilter
  //     }
  //     setLoading(false)
  //   });
  // }

  

  const getHunt  = async () => {

    try{
      const resp = await API.get("/hunt")
      if (resp.data.error === 0) {
        setHunt(resp.data.data);
       
      } 
      setLoading(false)
    } 
    catch (error) {
      console.log(error)
    }
  }
  //const [data, setData] = useState([]);
  
  const columns = React.useMemo(
    () => [
      {
        Header: "NOMBRE",
        accessor: "name",
        disableFilters: true,
        className: "colTest",
      },
      {
        Header: "PERFIL LINKEDIN",
        accessor: "linkedin_profile",
        disableFilters: true,
        Cell: ({row}) => {
          let linkeding_profile = row.original.linkedin_profile.split('/');
          linkeding_profile = linkeding_profile.filter(profile => profile)
        
          return linkeding_profile[linkeding_profile.length - 1]
        }
      },
      {
        Header: "PERFIL CONTACTO",
        accessor: "profile_name",
        disableFilters: false,
        Filter: SelectColumnProfileFilter
      },
      {
        Header: "FECHA PRIMER CONTACTO",
        accessor: "first_contact",
        disableFilters: false,
        Filter: SelectStatusFilter,
        filter: 'greaterThan'
      },
      {
        Header: "FECHA ULTIMO CONTACTO",
        accessor: "last_contact",
        disableFilters: false,
        Filter: SelectStatusFilter,
      },
      {
        Header: "ESTADO",
        accessor: "status_name",
        Filter: SelectStatusFilter,
        filter: "equals",
        Cell: ({ row }) => (
          <div className="estado_general">
            <span className={row.original.status_name}>
              {row.original.status_name}
            </span>
          </div>
        ),
      }, 
      {
        Header: "",
        id: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div div className="view-eyes" style={{display: 'flex', justifyContent: 'center'}}>
              <Link to={'/hunt/'+row.original.id}>
                <img
                  style={{ width: "22px" }}
                  src={ver}
                  alt=""
                  onClick={() => { }}
                />
              </Link>
            </div>
          );
        },
      },
      {
        Header: "",
        id: "delete",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div div className="view-eyes" style={{display: 'flex', justifyContent: 'center'}}>
                <img
                  style={{ width: "22px" }}
                  src={deleteIcon}
                  alt=""
                 onClick={()=> {
                  setShowModalByDelete(true)
                  handleShowModal(row.original.id)
                }}
                />
            </div>
          );
        },
      }
      /*{
        Header: "",
        accessor: "read",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div div className="view-eyes">
              <Link to={"/candidatos/"}>
                <img
                  style={{ width: "22px" }}
                  src={ver}
                  alt=""
                  onClick={() => {
                    console.log('holaaa')
                  }}
                />
              </Link>
            </div>
          );
        },
      },*/
    ],
    []
  );

  useEffect(() => {
    getHunt();
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-candidatos">
        <div className="contenido">
          <div className="contenido-actions">
            <Link className="action" to={'/crear_precandidato'} href="#">CARGAR NUEVO PRE-CANDIDATO</Link>
          </div>
          
          <div className="content-table with-departament">

            <Table columns={columns} loading={loading} data={hunt} />

          </div>
        </div>
      </div>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        className="modal_edit_accordion5"
      >
        <Modal.Header closeButton />

        <Modal.Body>
          <div className="vista-candidato">
            <div id="accordion">
              <Accordion1
                available={showModal}
                setAvailable={setShowModal}
                idSearch={idSearch}
                module={infoMudules}
                applicant={infoApplicant}
                disableEdit={userData.role_id === 5 ? true : false}
              />
              <Accordion2
                available={showModal}
                setAvailable={setShowModal}
                idSearch={idSearch}
                module={infoMudules}
                applicant={infoApplicant}
                disableEdit={userData.role_id === 5 ? true : false}
              />
              <Accordion3
                available={showModal}
                setAvailable={setShowModal}
                idSearch={idSearch}
                module={infoMudules}
                applicant={infoApplicant}
                disableEdit={userData.role_id === 5 ? true : false}
              />
              <Accordion5
                available={showModal}
                setAvailable={setShowModal}
                idSearch={idSearch}
                module={infoMudules}
                applicant={infoApplicant}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showModalByDelete}
        backdrop={'static'}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal_edit_applicant'
        onHide={handleCloseModal}

      >
        <Modal.Header closeButton />
        {
          isLoading  && (
            <Modal.Body  className='content-edit'> 
          <Row>
            <Col xs="12">
            <div className="spinner">
            <Spinner 
            animation="border" 
            variant="primary"
            style={{
              width: '4rem',
              height: '4rem',
              margin: 'auto',
              display: 'block'
            }}
            />
            </div>
            </Col>
          </Row>
          </Modal.Body>
          )    
        }
        {
          (!isLoading  && !HuntError) && (
            <Modal.Body  className='content-edit'>
          <Row>
            <Col xs="12">
              <h5 className="text-center">
              ¿Estás seguro que quieres Eliminar el candidato?
              </h5>
            </Col>
            <Col xs="12">
            <div className="d-flex justify-content-end mt-4">
            <button
            type="button"
            className="btn btn-primary mr-2"
            id="database"
            data-dismiss="modal"
           onClick={handleCloseModal}
          >
            Cancelar
          </button>
        <button
            type="button"
            className="btn btn-primary"
            id="database"
            data-dismiss="modal"
           onClick={(e) => {removeHunt(huntId)}}
          >
            ACEPTAR
          </button>
            </div>
            </Col>
          </Row>
        </Modal.Body>
          )
        }
        {
          HuntError && (
            <Modal.Body  className='content-edit'>
            <Row>
              <Col xs="12">
                <div className="alert alert-danger text-center mt-3 modal-alert-error ">
                  <h5>
                  hubo un error al eliminar el candidato
                  </h5>
                </div>
              </Col>
              <Col xs="12">
              <div className="d-flex justify-content-end mt-4">
              <button
              type="button"
              className="btn btn-primary mr-2"
              id="database"
              data-dismiss="modal"
             onClick={handleCloseModal}
            >
              Salir
            </button>
              </div>
              </Col>
            </Row>
          </Modal.Body>
          )
        }
        
      </Modal>
    </>
  );
};

export default Hunt;
